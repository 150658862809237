import { useEffect, useState } from "react";

import assert from "assert";

import MagicBell, {
  FloatingNotificationInbox,
} from "@magicbell/magicbell-react";
import { Box, Stack } from "@mui/material";
import { useRouter } from "next/router";

import SelectOption from "shared/components/navbar/NavbarLink";
import { getCurrentUser } from "shared/generated/clients/auth.client";
import { useAuth } from "shared/providers/AuthProvider";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import AddFleetModal from "../modals/AddFleetModal";

import AccountProfile from "./AccountProfile";
import AccountSwitcher from "./AccountSwitcher";

const DesktopViewAuthenticated: React.FunctionComponent<{
  currentPath: string;
}> = ({ currentPath }) => {
  const {
    fleets,
    currentFleetId: currentQueryParams,
    setCurrentFleetId,
    refetch,
    generateFleetUrl,
  } = useFleets();
  const router = useRouter();
  const { logout, currentUser } = useAuth();
  const { isInsurance } = useFleets();

  const [showAddFleetModal, setShowAddFleetModal] = useState(false);
  const [serviceupUser, setServiceupUser] = useState<
    | {
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        avatarUrl: string | null;
      }
    | undefined
  >();

  assert(
    process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
    "Magic Bell API key is missing"
  );

  const onAddFleetSubmit = async (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddFleetModal(false);
    await setCurrentFleetId(fleet.id);
    await refetch();
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await getCurrentUser({});
      if (response.error) {
        return;
      }

      setServiceupUser(response.data);
    };

    getUserInfo();
  }, [currentUser]);

  if (!currentUser?.email || !serviceupUser) return null;

  let tabs = [
    { path: "/request", label: isInsurance ? "Claims" : "Orders" },
    { path: "/vehicles", label: "Vehicles" },
    { path: "/history", label: "History" },
    { path: "/insights", label: "Insights" },
    { path: "/shops", label: "Shops" },
    { path: "/settings", label: "Settings" },
  ];

  if (isInsurance) {
    tabs = [
      { path: "/request", label: "Orders" },
      { path: "/history", label: "History" },
      { path: "/settings", label: "Settings" },
    ];
  }

  return (
    <Stack
      display="flex"
      justifyContent={"space-between"}
      width={"100%"}
      direction={"row"}
      gap={3}
    >
      <Stack spacing={5} direction={"row"} alignItems="center">
        {tabs.map(({ path, label }) => {
          return (
            <SelectOption
              key={path}
              currentPath={currentPath}
              href={generateFleetUrl(path)}
              text={label}
            />
          );
        })}
      </Stack>
      <Box display="flex" alignItems="center" flexGrow={1} gap={2}>
        {!!fleets?.length && (
          <Box display="flex" justifyContent="flex-end" flexGrow={1}>
            <AccountSwitcher
              fleets={fleets}
              setShowModal={setShowAddFleetModal}
            />
          </Box>
        )}
        <Box>
          <MagicBell
            apiKey={process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
            userEmail={currentUser.email}
            userExternalId={currentUser?.uid}
            locale="en"
            theme={{
              footer: {
                backgroundColor: "#FFFFFF",
                textColor: "#FFFFFF",
              },
            }}
          >
            {(props) => (
              <FloatingNotificationInbox
                width={400}
                height={500}
                placement="top-start"
                hideArrow
                notificationPreferencesEnabled={false}
                onNotificationClick={(notification) => {
                  router.push({
                    pathname: `/request/${notification.topic}`,
                    query: { fleetId: currentQueryParams },
                  });
                }}
                {...props}
              />
            )}
          </MagicBell>
        </Box>
        <Box>
          <AccountProfile serviceupUser={serviceupUser} logout={logout} />
        </Box>
      </Box>
      <AddFleetModal
        open={showAddFleetModal}
        onClose={() => setShowAddFleetModal(false)}
        onAddFleetSuccess={onAddFleetSubmit}
      />
    </Stack>
  );
};

export default DesktopViewAuthenticated;
