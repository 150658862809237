import { useEffect, useState } from "react";

import Head from "next/head";

import { useGetMyFleetsLazyQuery } from "shared/generated/graphql";
import { useAuth } from "shared/providers/AuthProvider";

export default function PageHead({
  title,
  shouldIncludeSubtitle = true,
}: {
  title?: string;
  shouldIncludeSubtitle?: boolean;
}) {
  let fullTitle = title;
  const { currentUser } = useAuth();
  const [getMyFleets, { data }] = useGetMyFleetsLazyQuery();
  const [project, setProject] = useState<string>();

  useEffect(() => {
    // Ideally, we could use the FleetProvider to get the project name
    // but we are not using it here because it's not a parent of this component
    async function loadUserData() {
      if (!currentUser) return;
      await getMyFleets();

      if (!data?.fleets) return;
      const isInsurance = data?.fleets.some((fleet) => fleet.isInsuranceFleet);

      setProject(isInsurance ? "Insurance" : "Fleets");
    }
    loadUserData();
  }, [currentUser, data?.fleets, getMyFleets]);

  if (shouldIncludeSubtitle) {
    const projectTitle = project ? `ServiceUp for ${project}` : "ServiceUp";
    fullTitle = title ? `${title} | ${projectTitle}` : projectTitle;
  }

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
    </Head>
  );
}
